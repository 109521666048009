import { useEffect, useMemo } from "react";
import { Widget } from "./Widget";
import { nanoid } from "nanoid";
import {useChat} from "../contexts/ChatProvider";

export const WidgetContainer = ({license = ""}) => {

  const {messages, sendMessage, makeApiCall, setShowTypingIndicator} = useChat();

  const remoteName = useMemo( () => {
    if ( license === "123" ) {
      return "Doozie shop";
    } else if (license === "456" ) {
      return "GAIP";
    } else if (license === "789" ) {
      return "Gigalogy";
    }
  },[license]);

  const handleSend = (message) => {
    setShowTypingIndicator(true);
    const newMessages = [
      {
        _id: nanoid(),
        text: message,
        user: "me",
        type: "outgoing",
        createdAt: new Date(),
      },
    ];
    sendMessage(newMessages);
    makeApiCall(message).then(() => {
      setShowTypingIndicator(false);
    }).catch(err => {
      console.log(err);
      sendMessage(      {
        _id: nanoid(),
        text: "Error sending message, please try again",
        user: "me",
        type: "incoming",
        createdAt: new Date(),
      });
      setShowTypingIndicator(false);
    });
  };

  return <Widget remoteName={remoteName}
                 onSend={handleSend} />

};
