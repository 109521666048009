import { v4 as uuidv4 } from 'uuid';



const ICONS = {
  hard: ["ウイスキー", "ジン", "ブランデー", "ウォッカ", "焼酎"],
  wine: ["ワイン", "シャンパン"],
  japanese: ["日本酒"],
};
export const generateUserId = () => {
  return uuidv4();
};

export const selectAvatarOnMessage = (string, defaultIcon=null) =>{
  // Check for matches in string
  for (const icon of Object.keys(ICONS)) {
    if (ICONS[icon] && ICONS[icon].some(keyword => string.includes(keyword))) {
      return icon; // Return the icon if a keyword is found in string
    }
  }

  return defaultIcon;
}

