import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import MediaContent from './MediaContent';
import TextContent from './TextContent';
import Timestamp from '../timestamp';
import getBorderCss from '../borderController';
import Avatar from './Avatar';
import { Container as MyMessageContainer, Wrapper as MyMessageWrapper, Background } from './OutgoingMessage';
import {useChat} from "../../contexts/ChatProvider";

const MessageContainer = styled(MyMessageContainer)`
    margin-left: 0px;
    box-sizing: border-box;
    margin-bottom: 0px;
`;

const Wrapper = styled(MyMessageWrapper)`
    justify-content: start;
    align-items: flex-start;
`;


const Name = styled.div`
    text-align: left;
    vertical-align: text-top;
    font-size: 14px;
    align-self: flex-start;
    line-height: auto;
    color: ${({ color }) => color || "#4b5563"};
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    user-select: none;
`;

const TextWrapper = styled.div`
    margin-left: 8px;
    box-sizing: border-box;
`;

const IncomingMessageBackground = styled(Background)`
    ${({ backgroundColor }) => !backgroundColor ? "opacity: 0.5;" : ""}
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 6px;
`;

export const IncomingMessage = ({
  text,
  media,
  user,
  avatar,
  showAvatar,
  showHeader,
  last,
  single,
  created_at

}) => {
  const { theme, colorSet } = useChat();
  const textColor = colorSet["--incoming-message-text-color"];
  const nameTextColor = colorSet["--incoming-message-name-text-color"];
  const linkColor = colorSet["--incoming-message-link-color"];
  const backgroundColor = colorSet["--incoming-message-background-color"];
  const timestampColor = colorSet["--incoming-message-timestamp-color"];
  const messageRef = useRef(null);

  const scrollDown = () => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(()=>{
    scrollDown();
  }, []);

  return (
      <Wrapper data-testid="incoming-message" className='fade-animation' ref={messageRef}>
        {showAvatar && <Avatar icon={avatar}  />}
        <TextWrapper>
          {showHeader &&
              <HeaderContainer>
                <Name color={nameTextColor}>{user?.name}</Name>
              </HeaderContainer>
          }
          <div style={{ display: "flex" }}>
            <MessageContainer>
              <IncomingMessageBackground
                  borderCss={(() => getBorderCss({ type: "incoming", last, single }))()}
                  backgroundColor={backgroundColor}
                  bgColor={backgroundColor || theme}
              />
              {media ? <MediaContent last={last} single={single} messageType='incoming' {...media} /> :
                  <TextContent linkColor={linkColor} color={textColor}>{text}</TextContent>}
              <Timestamp color={timestampColor} date={created_at} />
            </MessageContainer>
          </div>
        </TextWrapper>
      </Wrapper>
  );
}
