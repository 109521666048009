import { useRef } from "react";

const useDetectScrollPosition = () => {
  const ref = useRef();

  const detectTop = () => {
    if (ref.current) {
      return ref.current.scrollTop < 50;
    }
    return false;
  };

  const detectBottom = () => {
    if (ref.current) {
      const threshold = 100;
      return ref.current.scrollHeight - ref.current.scrollTop <= ref.current.clientHeight + threshold;
    }
    return false;
  };

  return { ref, detectTop, detectBottom };
};

export default useDetectScrollPosition;