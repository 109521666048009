import React from 'react';
import styled from 'styled-components';

export const Content = styled.div`
    text-align: left;
    vertical-align: text-top;
    font-size: 14px;
    align-self: flex-start;
    line-height: auto;
    color: ${({ color }) => color || '#000000'};
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;
    box-sizing: border-box;
    word-wrap: break-word;
    width: 100%;

    user-select: none;

    a {
        color: ${({ linkColor }) => linkColor || 'blue'};
    }
`;

export default function TextContent({ linkColor, color, children = '' }) {
  
  const formatText = (text) => {
    // Replace headers with <h3> tags
    text = text.replace(/### (.+?)(?=\n|$)/g, '<h3>$1</h3>');

    // Replace list items with <li> tags inside a <ul>
    text = text.replace(/- (.+?)(?=\n|$)/g, '<li style="list-style:disc;">$1</li>');
    text = text.replace(/(<li>.+<\/li>)/g, '<ul >$1</ul>');
    
    // Replace bold text with <strong> tags
    text = text.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');

    // Replace image with <img> tag
    text = text.replace(/!\[(.+?)\]\((.+?)\)/g, '<img alt="$1" src="$2" height="160" width="160" />');

    // Replace links with <a> tags
    text = text.replace(/\[(.+?)\]\((.+?)\)/g, '<a href="$2" target="_blank">$1</a>');

    // Replace newlines with <br> tags
    text = text.replace(/\n/g, '<br />');

    return text;
  };
  
  // console.log("message = ", children)
  // console.log("formattedText = ", formattedText)
  return (
      <Content
          linkColor={linkColor}
          color={color}
          dangerouslySetInnerHTML={{ 
            __html: formatText(children)
         }}
      />
  );
}
