import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import getBorderCss from '../borderController';
import {useChat} from "../../contexts/ChatProvider";
import CarouselMedia from "./CarouselMedia";

const MyMessageWrapper = styled.div`
    display:flex;
    justify-content: end;
    margin-top: ${({ firstMessage }) => firstMessage ? "16px" : "2px"};
    position: relative;
    box-sizing: border-box;
    margin-bottom: ${({ lastMessage }) => lastMessage ? "16px" : "2px"};
    z-index: 1;
`

const MyMessageContainer = styled.div`
  //max-width:272px;
  //min-width:80px;
  justify-content:flex-end;
  align-items:flex-end;
  //gap:10px;
  position:relative;
  box-sizing: border-box;
`
const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color:${({ bgColor }) => bgColor};

    // ${({ borderCss }) => borderCss};
`

const MessageContainer = styled(MyMessageContainer)`
  margin-left: 0px;
  box-sizing: border-box;
  margin-bottom: 0px;
`;

const Wrapper = styled(MyMessageWrapper)`
  justify-content: start;
  align-items: flex-end;
`;

const DPContainer = styled.div`
  width: 32px;
  height: 32px;
  margin-left: 10px;
  box-sizing: border-box;
  user-select: none;
`;

const DisplayPicture = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 9999px;
  box-sizing: border-box;
  border-width: 2px;
  border-color: rgb(255 255 255);
  object-fit: cover;
  position: relative;
  z-index: 1;
`;

const Name = styled.div`
  text-align: left;
  vertical-align: text-top;
  font-size: 14px;
  align-self: flex-start;
  line-height: auto;
  color: ${({ color }) => color || "#4b5563"};
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  user-select: none;
`;

const TextWrapper = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  box-sizing: border-box;
`;

const IncomingMessageBackground = styled(Background)`
  ${({ backgroundColor }) => !backgroundColor ? "opacity: 0.5;" : ""}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: "center";
  margin-top: 16px;
  margin-bottom: 6px;
`;

export default function CarouselMessage({
                                          text,
                                          _id,
                                          loadImages,
                                          recommendations,
                                          media,
                                          user,
                                          showAvatar,
                                          showHeader,
                                          last,
                                          single,
                                          created_at,
                                          windowWidth
                                        }) {
  const { theme, colorSet } = useChat();

  // useEffect(() => {
  //   if (user?.avatar && user.avatar.trim().length > 0) {
  //     setAvatar(user.avatar);
  //   }
  // }, [user]);

  const textColor = colorSet["--incoming-message-text-color"];
  const nameTextColor = colorSet["--incoming-message-name-text-color"];
  const linkColor = colorSet["--incoming-message-link-color"];

  const backgroundColor = colorSet["--incoming-message-background-color"];
  const timestampColor = colorSet["--incoming-message-timestamp-color"];

  return (
      <Wrapper
          data-testid="incoming-message"
          className='fade-animation'
          style={{justifyContent: recommendations.length > 2 ? 'start' : 'center'}}
      >

        <TextWrapper>
          {showHeader &&
              <HeaderContainer>
                <Name color={nameTextColor}>{user?.name}</Name>
              </HeaderContainer>
          }

          <MessageContainer
          >
            <IncomingMessageBackground
                // borderCss={(() => getBorderCss({
                //   type: "incoming",
                //   last,
                //   single,
                // }))()}
                // backgroundColor={backgroundColor}
                // bgColor={backgroundColor || theme}
            />

            <CarouselMedia
                _id={_id}
                last={last}
                single={single}
                messageType='incoming'
                recommendations={recommendations}
                loadImages={loadImages}
                windowWidth={windowWidth}
                {...media} />
          </MessageContainer>
        </TextWrapper>
      </Wrapper>
  );
}
