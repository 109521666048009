import React from 'react';
import "./index.css";

const ContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const InnerContainerStyle = (color) => ({
  boxSizing: "border-box",
  display: "block",
  width: "8px",
  height: "8px",
  position: "absolute",
  left: "0",
  right: "0",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "auto",
  marginBottom: "auto",
  top: "0",
  bottom: "0",
  border: `2px solid ${color || '#fff'}`,
  borderRadius: "50%",
  animation: "lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
  borderColor: `${color || '#fff'} transparent transparent transparent`
});

const Loading = ({ color }) => {
  return (
      <div style={ContainerStyle} className='fade-animation-slow'>
        <div className="message-lds-ring">
          <div style={InnerContainerStyle(color)} />
          <div />
          <div />
          <div />
        </div>
      </div>
  );
};

export default Loading;
