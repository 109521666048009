import React from 'react';

const getBorderCss = ({ type, last, single }) => {
  let borderTopLeft, borderTopRight, borderBottomLeft, borderBottomRight;

  if (type === 'outgoing') {
    borderTopLeft = true;
    borderBottomLeft = true;
    borderBottomRight = last ? true : false;
    borderTopRight = !last && single ? true : false;
  } else {
    borderTopRight = true;
    borderBottomRight = true;
    borderBottomLeft = single || last ? true : false;
    borderTopLeft = last ? true : false;
  }

  return {
    borderTopLeftRadius: borderTopLeft ? '8px' : '2px',
    borderTopRightRadius: borderTopRight ? '8px' : '2px',
    borderBottomLeftRadius: borderBottomLeft ? '8px' : '2px',
    borderBottomRightRadius: borderBottomRight ? '8px' : '2px',
  };
};

export default getBorderCss;