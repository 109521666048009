import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import MediaContent from './MediaContent';
import TextContent from './TextContent';
import Timestamp from '../timestamp';
import getBorderCss from '../borderController';
import {useChat} from "../../contexts/ChatProvider";

export const Wrapper = styled.div`
    display: flex;
    justify-content: end;
    margin-right: 10px;
    margin-top: ${({ firstMessage }) => (firstMessage ? '16px' : '2px')};
    position: relative;
    box-sizing: border-box;
    margin-bottom: ${({ lastMessage }) => (lastMessage ? '16px' : '2px')};
    z-index: 1;
`;

export const Container = styled.div`
    max-width: 272px;
    min-width: 80px;
    margin-left: 10px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    position: relative;
    box-sizing: border-box;
`;

export const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({ bgColor }) => bgColor};
    ${({ borderCss }) => borderCss};
`;

export default function OutgoingMessage({
                                    text,
                                    media,
                                    loading,
                                    last,
                                    single,
                                    clusterFirstMessage,
                                    clusterLastMessage,
                                    created_at,
                                    seen,
                                    showHeader,
                                    showAvatar,
                                    type,
                                    scrollToBottom
                                  }) {
  const { theme, colorSet } = useChat();

  const textColor = colorSet["--outgoing-message-text-color"];
  const backgroundColor = colorSet["--outgoing-message-background-color"];
  const timestampColor = colorSet["--outgoing-message-timestamp-color"];
  const checkmarkColor = colorSet["--outgoing-message-checkmark-color"];
  const loaderColor = colorSet["--outgoing-message-loader-color"];
  const linkColor = colorSet["--outgoing-message-link-color"];
  
  useEffect(()=>{
    scrollToBottom();
  },[]);
  
  return (
      <Wrapper
          data-testid="outgoing-message"
          lastMessage={clusterLastMessage}
          firstMessage={clusterFirstMessage}
          className='fade-animation'
      >
        <Container>
          <Background
              borderCss={getBorderCss({
                type: "outgoing",
                last,
                single
              })}
              bgColor={backgroundColor || theme}
          />

          {media ? (
              <MediaContent
                  last={last}
                  single={single}
                  messageType='outgoing'
                  {...media}
              />
          ) : (
              <TextContent
                  linkColor={linkColor}
                  color={textColor}
              >
                {text}
              </TextContent>
          )}

          <Timestamp
              showSeen
              color={timestampColor}
              loaderColor={loaderColor}
              checkmarkColor={checkmarkColor}
              date={created_at}
              seen={seen}
              loading={loading}
          />
        </Container>
      </Wrapper>
  );
}
