import React from 'react';

const MinusIcon = ({ color, ...props }) => (
    <svg 
       fill="white" 
       height="24" 
       viewBox="0 0 24 24" 
       width="24" 
       xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            d="m18 12.75h-12c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h12c.41 0 .75.34.75.75s-.34.75-.75.75z" 
            fill={color}
        />
    </svg>
);

export default MinusIcon;