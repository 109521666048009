import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_MAIRA_AI_ASSISTANT_URL}api`;

// Retry logic for API calls
const withRetry = async (fn, maxRetries, delay) => {
  let retries = 0;
  while (retries < maxRetries) {
    try {
      return await fn();
    } catch (error) {
      console.error('API Error:', error);
      retries++;
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
  throw new Error('Max retries exceeded');
};

export const fetchConversations = async (userId) => {
  try {
    const response = await axios.get(`${BASE_URL}/conversations?user_id=${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching conversations:', error);
    throw error;
  }
};
