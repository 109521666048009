import { useChat } from "../contexts/ChatProvider";
import {
  MinChatUiProvider,
  MainContainer,
  MessageContainer,
  MessageHeader,
} from "@minchat/react-chat-ui";
import MessageInput from './message-input';
import MessageList from "./message/MessageList";
import {useEffect, useState} from "react";
import MinusIcon from '../minusIcon';

export const Widget = ({remoteName = "", onSend}) => {
  const { hide, theme, colorSet, loading, header } = useChat();

  return (
      <div className="chatbot-container shadow-gray-400 shadow-md relative md:w-96 bg-white rounded-xl shadow-md shadow-gray-400 md:mr-6">
        <MinChatUiProvider
            theme={theme}
            colorSet={colorSet}
        >
          <MainContainer>
            <MessageContainer>
              <MessageHeader showBack={false}>
                <div style={{display:"flex", justifyContent:"space-between", padding:"0 5% 0 5%"}}>
                  <p id="ChatBotTitle">
                    { header }
                  </p>
                  <span onClick={()=>{hide()}}
                        style={{cursor:"pointer"}}
                  >
                    <MinusIcon color={colorSet["--message-header-text-color"] ?? "#000"}/>
                  </span>
                </div>
              </MessageHeader>
              <MessageList
                  loading={loading}
              >
              </MessageList>
              <MessageInput placeholder="Type message here"
                            onSendMessage={onSend}
                            showAttachButton={false}/>
            </MessageContainer>
          </MainContainer>
        </MinChatUiProvider>
      </div>

  );
};
