export const isValidJSON = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}

export const constructObjectFromJson = (message) => {
  // const capturedString = message.replace(/'/g, '"');
  try {
    const parsedstring = JSON.parse(message);
    return parsedstring;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const constructObjectFromJson2 = (message) => {
  let jsonPart = null;
  const startIndex = message.indexOf('{');
  const endIndex = message.lastIndexOf('}');
  if (startIndex !== -1 && endIndex !== -1) {
    const capturedString = message.substring(startIndex, endIndex + 1)
        .split(/'|\\n/)
        .join('');
    // split(/'|\\n/) breaks the string into an array of substrings using either a single quote (') or a newline character (\n) as the delimiter.
    // join('') then concatenates the array back into a single string with the specified separator (''), effectively removing the matched characters.
    if(isValidJSON(capturedString)){
      jsonPart = JSON.parse(capturedString);
    }
  }
  return jsonPart;
}

export const extractAndValidateJson = (string) => {
  let jsonPart = '';
  let nonJsonPart = null;
  let jsonStartIndex = -1;
  let jsonEndIndex = -1;
  let parsedJson = null;

  // Check if JSON is enclosed in backticks (```)
  const backtickIndex = string.indexOf('```');
  if (backtickIndex !== -1) {
    jsonStartIndex = backtickIndex + 3; // Skip the three backticks
    const secondBacktickIndex = string.indexOf('```', jsonStartIndex);
    if (secondBacktickIndex !== -1) {
      jsonPart = string.substring(jsonStartIndex, secondBacktickIndex);
      jsonEndIndex = secondBacktickIndex;
    }
    // Extract non-JSON part
    nonJsonPart = string.substring(0, jsonStartIndex - 3) + string.substring(jsonEndIndex + 3);
    parsedJson = constructObjectFromJson2(jsonPart);

    return { nonJsonPart: nonJsonPart, parsedJson };
  } else {
    let jsonPart = '';
    let nonJsonPart = '';

    let startIndex = string.indexOf('{');
    if (startIndex === -1) {
      // No JSON object found, return the entire string as non-JSON part
      return { jsonPart: '', nonJsonPart: string, parsedJson: null };
    }

    // Find the end index of the JSON object
    let endIndex = string.lastIndexOf('}');
    if (endIndex === -1) {
      // No matching closing brace found, return error
      console.error('Invalid JSON: No matching closing brace found.');
      return { jsonPart: '', nonJsonPart: trimInvalidCharacters(string), parsedJson: null };
    }

    // Extract the JSON object and non-JSON parts
    jsonPart = string.substring(startIndex, endIndex + 1);
    nonJsonPart = string.substring(0, startIndex) + string.substring(endIndex + 1);

    return { jsonPart, nonJsonPart: trimInvalidCharacters(nonJsonPart), parsedJson : constructObjectFromJson(jsonPart) };
  }
}

export const trimInvalidCharacters = (string) => {
  // Define a regular expression to match invalid characters
  const invalidCharactersRegex = /[\n`]/g;

  // Use the replace() method to remove invalid characters
  const trimmedString = string.replace(invalidCharactersRegex, '');

  return trimmedString;
}

export const CURRENCY = "¥";
export const formatCurrency = (x, currency = CURRENCY) => {
  return currency + " " + x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
