import React, {useEffect, useState, useRef, useLayoutEffect} from 'react';
import styled from 'styled-components';
import {useChat} from "../../contexts/ChatProvider";
import {formatCurrency} from "../../utils/message";
import { Swiper, SwiperSlide, Navigation } from '../../utils/swiper.js';

const CarouselNavButton = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px; /* Adjust the font size */
    color: rgba(0, 0, 0, 0.5);
    background: #fff;
    cursor: pointer;
    opacity: 1;
    padding: 2px;
    box-shadow: none;
    border: 0px solid RGBA(0, 0, 0, 1);
    border-radius: 5px;
`;

const PrevButton = styled(CarouselNavButton)`
    left: 1px;
    z-index: 1000;
`;

const NextButton = styled(CarouselNavButton)`
    right: 1px;
    z-index: 1000;
`;

const PlaceholderImage = styled.div`
    width: 160px;
    height: 160px; /* Set your desired max height */
    background-color: #ccc; /* Set your placeholder color */
`;


const ImageContainer = styled.div`
    flex: 1 0 auto; /* Each image container takes equal space */
    padding: 4px;
    position: relative;
    user-select: none;
`;

const Image = styled.img`
    width: 160px;
    height: 160px;
    margin: 0px;
    position: relative;
    object-fit: cover;
    cursor: pointer;
        // ${({borderCss}) => borderCss};
`;

const ProductInfo = styled.div`
    max-width: 140px; // almost equal but less than the image
    padding: 1px;
    position: relative;
`;

const ProductInfoTitleContainer = styled.div`
    height: 62px; /* Set the maximum height */
    overflow: hidden; /* Hide overflowed content */
    margin-top: 2px;
    margin-bottom: 2px;
`;

const ProductInfoTitle = styled.div`
      color: #989898;
      font-size: 14px;
      line-height: 1.25rem;
      text-shadow: none;
      font-style: normal;
      text-decoration: none;
      text-align: inherit;
      letter-spacing: 1px;
      word-spacing: normal;
      text-transform: uppercase;
      font-weight: bold;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* Limit to 3 lines */
      overflow: hidden;
      text-overflow: ellipsis; /* Show ellipsis for overflowed text */
`;

const ProductInfoPrice = styled.div`
      color: rgba(31, 41, 55, 1);
      font-size: 14px;
      line-height: 1.5rem;
      font-weight: 600;
`;

const BuyNowButton = styled.div`
      display: block;
      width: 100%;
      padding: 2px 20px; /* Adjust padding as needed */
      font-size: 14px; /* Adjust font size as needed */
      font-weight: 500;
      text-align: center;
      border-radius: 5px; /* Rounded corners */
      background-color: transparent; /* Transparent background */
      cursor: pointer;
      transition: all 0.3s ease; /* Smooth transition effect */
  
      /* Optional: Add hover effect */
      &:hover {
          background-color: ${props => props.theme || '#333'};
          border-color: ${props => props.theme || '#333'};
          color: #fff !important; /* Text color on hover */
      }
`;



const CarouselContainer = styled.div`
    //width: 100%;
    //max-height: 200px;
    overflow: hidden;
    position: relative;
`;

const CarouselContent = styled.div`
    display: flex;
`;

const ArrowLeft = () => (
    <svg fill="#000000" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
         width="28px" viewBox="0 0 100 100">
      <g>
        <path d="M33.934,54.458l30.822,27.938c0.383,0.348,0.864,0.519,1.344,0.519c0.545,0,1.087-0.222,1.482-0.657
		c0.741-0.818,0.68-2.083-0.139-2.824L37.801,52.564L64.67,22.921c0.742-0.818,0.68-2.083-0.139-2.824
		c-0.817-0.742-2.082-0.679-2.824,0.139L33.768,51.059c-0.439,0.485-0.59,1.126-0.475,1.723
		C33.234,53.39,33.446,54.017,33.934,54.458z"/>
      </g>
    </svg>
);

const ArrowRight = () => (
    <svg fill="#000000" width="28px"version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 100 100" enableBackground="new 0 0 100 100"
         ><g id="SVGRepo_bgCarrier"></g>
      <g id="SVGRepo_iconCarrier"> <g><path
          d="M66.066,45.542L35.244,17.604c-0.383-0.348-0.864-0.519-1.344-0.519c-0.545,0-1.087,0.222-1.482,0.657
             c-0.741,0.818-0.68,2.083,0.139,2.824L62.199,47.436L35.33,77.079c-0.742,0.818-0.68,2.083,0.139,2.824
             c0.817,0.742,2.082,0.679,2.824-0.139L66.232,48.941c0.439-0.485,0.59-1.126,0.475-1.723
             C66.766,46.61,66.554,45.983,66.066,45.542z">
         </path>
     </g>
 </g>
</svg>
);

const maxImageLoadRetry = 3;

export default function CarouselMedia({
                                        type,
                                        _id,
                                        recommendations,
                                        loadImages = false,
                                        urls,
                                        size,
                                        last,
                                        single,
                                        messageType
                                        ,windowWidth 
                                      }) { 
  const imageContentRef = useRef(null);
  const {theme, colorSet} = useChat();
  const [imagesLoaded, setImagesLoaded] = useState(Array(recommendations.length).fill(false));
  const [imagesRetry, setImagesRetry] = useState(Array(recommendations.length).fill(0));

  const handleImageError = (event, index) => {
    if (imagesRetry[index] > maxImageLoadRetry) return;
    const img = event.target;
    // Retry fetching the image after a delay
    setTimeout(() => {
      if (recommendations[index].hasOwnProperty('image_urls')) {
        img.src = recommendations[index].image_urls[0];
      } else if (recommendations[index].hasOwnProperty('variant_image_src')) {
        img.src = recommendations[index].variant_image_src;
      }
      setImagesRetry(prev => {
        const newState = [...prev];
        newState[index] = newState[index] + 1;
        return newState;
      });
    }, 1000); // Adjust the delay as needed
  };

  useEffect(() => {
    const handleLoad = (index) => {
      setImagesLoaded(prev => {
        const newState = [...prev];
        newState[index] = true;
        return newState;
      });
    };

    const handleError = (index) => {
      setImagesLoaded(prev => {
        const newState = [...prev];
        newState[index] = true;
        return newState;
      });
    };

    const images = document.querySelectorAll(`.carousel-image-${_id}`);

    images.forEach((img, index) => {
      img.addEventListener('load', () => handleLoad(index));
      img.addEventListener('error', () => handleError(index));
    });

    return () => {
      images.forEach((img, index) => {
        img.removeEventListener('load', () => handleLoad(index));
        img.removeEventListener('error', () => handleError(index));
      });
    };
  }, [recommendations]);
  return (
      <CarouselContainer
          // className="lazy-carousel"
          data-src={_id}
      >
        <CarouselContent
         style={{width: (windowWidth-20) }}
        >
          <Swiper
            id="corousel"
            modules={[Navigation]}
            slidesPerView={recommendations.length > 2 ? 2.2 : recommendations.length}
            spaceBetween={0} 
            navigation={{
              nextEl: '.next',
              prevEl: '.prev',
            }}
          >
          {recommendations.map((item, index) => (
            <SwiperSlide>
              <ImageContainer
                key={index}
                ref={imageContentRef}
              >
                {/* {!imagesLoaded[index] && (
                    <PlaceholderImage/>
                )} */}
                <Image
                    className={"carousel-image-" + _id}
                    src={item.hasOwnProperty('image_urls') ? item.image_urls[0] : item.hasOwnProperty('variant_image_src') ? item.variant_image_src : null}
                    alt={item.hasOwnProperty('title') ? item.title : item.hasOwnProperty('product_title') ? item.product_title : ''}
                    onError={(event) => handleImageError(event, index)}
                    // style={{display: imagesLoaded[index] ? 'block' : 'none'}}
                    onClick={() => {
                      if (item.hasOwnProperty('item_url')) {
                        window.open(item.item_url, "_blank")
                      } else if (item.hasOwnProperty('online_store_preview_url')) {
                        window.open(item.online_store_preview_url, "_blank")
                      }
                    }}
                />
                <ProductInfo>
                  <ProductInfoTitleContainer>
                    <ProductInfoTitle>
                      {item.title || item.product_title}
                    </ProductInfoTitle>
                  </ProductInfoTitleContainer>
                  <ProductInfoPrice>
                    { item.price && formatCurrency(item.price) }
                  </ProductInfoPrice>
                  <BuyNowButton
                      theme={theme}
                      style={{
                        color: theme,
                        border: `2px solid ${theme}`,
                      }}
                      onClick={() => {
                        if (item.hasOwnProperty('item_url')) {
                          window.open(item.item_url, "_blank")
                        } else if (item.hasOwnProperty('online_store_preview_url')) {
                          window.open(item.online_store_preview_url, "_blank")
                        }
                      }}
                  >
                    商品ページ
                  </BuyNowButton>
                </ProductInfo>
                {/* Product information */}


                {/*<ButtonsContainer>*/}
                {/*  <Button*/}
                {/*      style={{backgroundColor: theme}}*/}
                {/*      onClick={() => window.open(item.item_url, "_blank")}*/}
                {/*  >*/}
                {/*    View Details*/}
                {/*  </`Button>*/}
                {/*  <Button style={{backgroundColor: theme}}*/}
                {/*          onClick={() => window.open(item.item_url, "_blank")}*/}
                {/*  >*/}
                {/*    Shop Now*/}
                {/*  </Button>*/}
                {/*</ButtonsContainer>*/}
              </ImageContainer>
              </SwiperSlide>
          ))}
          {/* {recommendations.length > 2 && ( */}
              <>
                <PrevButton className={"prev"} ><ArrowLeft/></PrevButton>
                <NextButton className={"next"} ><ArrowRight/></NextButton>
              </>
          {/* )} */}
          </Swiper>
        </CarouselContent>
      </CarouselContainer>
  );
}
