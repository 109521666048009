import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import useDetectScrollPosition from '../../hooks/useDetectScrollPosition';
import {
  MessageListBackground,
  MessageHeader,
  Loading,
} from "@minchat/react-chat-ui";
import { useChat } from "../../contexts/ChatProvider";
import CarouselMessage from "./CarouselMessage";
import Message from "./Message";
import Avatar from "./Avatar";
import TypingIndicator from "../typing-indicator";

const Container = styled.div`
    height: 100%;
    position: relative;
    max-height: 100vh;
    overflow-y: hidden;
    padding-left: 0px;
    padding-right: 12px;
    padding-bottom: 2px !important;
`;

const InnerContainer = styled.div`
    height: 100%;
    padding-bottom: 2px !important;
`;

const ScrollContainer = styled.div`
    overflow-y: auto;
    position: relative;
    height: 100%;
    width: 100%;
    max-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;

const Buffer = styled.div`
    height: 6px;
    width: 100%;
    position: relative;
`;

const NoMessagesTextContainer = styled.div`
  color: rgba(0, 0, 0, 0.36);
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  -select: none;
`;

const RetryLink = styled.div`
    color: #007bff; /* Set the text color */
    text-decoration: underline; /* Add underline decoration */
    cursor: pointer; /* Show pointer cursor on hover */
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 14px;
`;

const RetryMessageContainer = styled.div`
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 14px;
    margin: 2px;
`;

const RetryContainer = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
`;

const MessageList = ({
                       loading = false,
                       onScrollToTop,
                       themeColor = '#6ea9d7',
                       typingIndicatorContent,
                       customTypingIndicatorComponent,
                     }) => {
  const {
    showTypingIndicator,
    theme,
    messages,
    fetchConversations,
    setLoading,
    loadConversation,
    error,
    setError,
  } = useChat();
  const [messagesWasEmpty, setMessagesWasEmpty] = useState(true);
  const containerRef = useRef(null);
  const bottomBufferRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const { detectBottom, detectTop } = useDetectScrollPosition(scrollContainerRef);
  const [loadingMore, setLoadingMore] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  // useLayoutEffect(() => {
  //   if(!loadingMore){
  //     setTimeout(() => {
  //       scrollToBottom();
  //     }, 0);
  //   }
  // }, [messages, bottomBufferRef.current, scrollContainerRef.current]);

  useEffect(() => {
    if (detectBottom()) {
      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
  }, []);

  // Initial call
  useEffect(() => {
    if (loadConversation) {
      setLoading(true);
      fetchConversations()
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
    }
  }, [loadConversation]);

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (container.scrollTop === 0) {
      // 1. Preserve the current scroll position
      const currentScrollPosition = container.scrollHeight - container.scrollTop;
      setLoadingMore(true);
      fetchConversations()
          .then(() => {
            setLoadingMore(false);
            // 3. Restore the scroll position after conversations are loaded
            // container.scrollTop = container.scrollHeight - currentScrollPosition - 60;
            container.scrollTop = container.scrollHeight - currentScrollPosition;
          })
          .catch(() => setLoadingMore(false));
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToBottom = () => {
    if (bottomBufferRef.current && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const scrollPoint = bottomBufferRef.current;
      const parentRect = container.getBoundingClientRect();
      const childRect = scrollPoint.getBoundingClientRect();
      const scrollOffset = childRect.top + container.scrollTop - parentRect.top;

      if (container.scrollBy) {
        container.scrollBy({ top: scrollOffset, behavior: "auto" });
      } else {
        container.scrollTop = scrollOffset;
      }
    }
  };

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     try {
  //       const response = await fetch('backendImageUrl'); // Replace 'backendImageUrl' with the actual URL of the image on your backend
  //       const blob = await response.blob();
  //       const dataUrl = await blobToDataUrl(blob);
  //       setImageUrl(dataUrl);
  //     } catch (error) {
  //       console.error('Error fetching image:', error);
  //     }
  //   };
  //
  //   fetchImage();
  // }, []);
  //
  // // Function to convert blob to data URL
  // const blobToDataUrl = (blob) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result);
  //     reader.onerror = reject;
  //     reader.readAsDataURL(blob);
  //   });
  // };

  return (
      <Container ref={containerRef}>
        <MessageListBackground roundedCorners={false} />
        <InnerContainer>
          {loading ? (
              <LoadingContainer>
                {<Loading themeColor={theme} />}
              </LoadingContainer>
          ) : error ?
              <RetryContainer>
                <RetryMessageContainer>
                  Failed to load the conversation
                </RetryMessageContainer>
                <RetryLink onClick={() => {
                  setLoading(true);
                  setError('');
                  fetchConversations()
                      .then(() => setLoading(false))
                      .catch(() => setLoading(false));
                }}>
                  Try again
                </RetryLink>
              </RetryContainer>
              : (
              <ScrollContainer
                  onScroll={handleScroll}
                  ref={scrollContainerRef}
              >
                {messages && messages.map((message, index) => {
                  // Determine the type of message to render
                  let lastClusterMessage, firstClusterMessage, last, single;


                  //if it is the first message in the messages array then show the header
                  if (index === 0) {
                    firstClusterMessage = true
                  }
                  //if the previous message from a different  then show the header
                  if (index > 0 && messages[index - 1].user !== message.user) {
                    firstClusterMessage = true
                  }
                  //if it is the last message in the messages array then show the avatar and is the last incoming
                  if (index === messages.length - 1) {
                    lastClusterMessage = true;
                    last = true
                  }
                  //if the next message from a different user then show the avatar and is last message incoming
                  if (index < messages.length - 1 && messages[index + 1].user !== message.user) {
                    lastClusterMessage = true;
                    last = true
                  }
                  //if the next message and the previous message are not from the same message.user then single incoming is true
                  if (index < messages.length - 1 && index > 0 && messages[index + 1].user !== message.user && messages[index - 1].user !== message.user) {
                    single = true
                  }
                  //if it is the first message in the messages array and the next message is from a different user then single incoming is true
                  if (index === 0 && index < messages.length - 1 && messages[index + 1].user !== message.user) {
                    single = true
                  }
                  //if it is the last message in the messages array and the previous message is from a different user then single incoming is true
                  if (index === messages.length - 1 && index > 0 && messages[index - 1].user !== message.user) {
                    single = true
                  }
                  //if the messages array contains only 1 message then single incoming is true
                  if (messages.length === 1) {
                    single = true
                  }

                  if (message.type === 'outgoing') {
                    return (
                        <div key={message._id} style={{marginTop: '16px'}}>
                          <Message
                              type={message.type}
                              text={message.text}
                              seen={message.seen}
                              created_at={message.createdAt}
                              showAvatar={true}
                              last={single ? false : last}
                              single={single}
                              scrollToBottom={scrollToBottom}
                              // Add other message properties as needed
                          />
                        </div>
                    )
                  } else if (message.type === 'carousel') {
                    return (
                        <CarouselMessage
                            type='incoming'
                            key={message._id}
                            _id={message._id}
                            // loadImages={visibleCarousels.includes(message._id)}
                            user={message.user}
                            recommendations={message.recommendations}
                            media={{urls: ['https://picsum.photos/id/1/100', 'https://picsum.photos/id/2/100', 'https://picsum.photos/id/3/100']}}
                            seen={message.seen}
                            created_at={message.createdAt}
                            // showAvatar={lastClusterMessage}
                            showAvatar={true}
                            // showHeader={firstClusterMessage}
                            // last={single ? false : last}
                            // single={single}
                            text={''}
                            windowWidth={containerRef.current.clientWidth}
                        />
                    )
                  } else {
                    return (
                        <div key={message._id}>
                          <Message
                              type={message.type}
                              text={message.text}
                              seen={message.seen}
                              created_at={message.createdAt}
                              showAvatar={true}
                              avatar={message.avatar}
                              showHeader={firstClusterMessage}
                              clusterFirstMessage={firstClusterMessage}
                              clusterLastMessage={lastClusterMessage}
                              last={single ? false : last}
                              single={single}
                              style={{
                                marginRight: '10px',
                                marginTop: '16px !important',
                                marginBottom: '16px !important'
                              }}
                              // Add other message properties as needed
                          />
                        </div>
                    );
                  }
                })}

                {showTypingIndicator && (
                    <div style={{display: 'flex', justifyContent: 'start'}}>
                      {customTypingIndicatorComponent ?
                          customTypingIndicatorComponent
                          :
                          <TypingIndicator
                              content={typingIndicatorContent}
                              themeColor={theme}/>}
                    </div>
                )}

          {/* Bottom buffer */}
          <Buffer ref={bottomBufferRef}/>
        </ScrollContainer>
          )}
        </InnerContainer>
      </Container>
  );
};

export default MessageList;
