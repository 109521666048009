import React from 'react';
import OutgoingMessage from './OutgoingMessage';
import {IncomingMessage} from './IncomingMessage';

function Message({
                   text,
                   media,
                   created_at,
                   seen,
                   loading,
                   type = "outgoing",
                   user,
                   showAvatar,
                   avatar,
                   showHeader,
                   last,
                   single,
                   clusterFirstMessage,
                   clusterLastMessage,
                   scrollToBottom = null
                 }) {
  return (
      type === "outgoing" ?
          <OutgoingMessage
              loading={loading}
              text={text}
              created_at={created_at}
              seen={seen}
              media={media}
              last={last}
              single={single}
              clusterFirstMessage={clusterFirstMessage}
              clusterLastMessage={clusterLastMessage}
              scrollToBottom={scrollToBottom}
          />
          :
          <IncomingMessage
              showAvatar={showAvatar}
              text={text}
              created_at={created_at}
              media={media}
              user={user}
              showHeader={showHeader}
              last={last}
              single={single}
              avatar={avatar}
          />
  );
}

export default Message;
