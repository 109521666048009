import { useEffect, useState } from "react";

const useTypingListener = (props) => {
  const [typing, setTyping] = useState(false);

  let timeout;

  useEffect(() => {
    // Call the function when typing starts or ends but should not call it on every render and should only be called when the value of typing changes
    if (typing) {
      props.onStartTyping && props.onStartTyping();
    } else {
      props.onEndTyping && props.onEndTyping();
    }

  }, [typing]);

  const onKeyDown = () => {
    clearTimeout(timeout);
    setTyping(true);
  };

  const onKeyUp = () => {
    timeout = setTimeout(() => {
      setTyping(false);
    }, 2000);
  };

  return { setTyping, onKeyUp, onKeyDown };
};

export default useTypingListener;
