import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import mairaIcon from '../../maira-advisor-icon.png';
import generalIcon from '../../general.png';
import hardIcon from '../../hard.png';
import wineIcon from '../../wine.png';
import japaneseIcon from '../../japanese.png'; // Assuming you have an image for the "japanese" avatar
import {useChat} from "../../contexts/ChatProvider";

const DPContainer = styled.div`
    width: 42px;
    height: 42px;
    margin-top: 21px;
    margin-left: 10px;
    box-sizing: border-box;
    user-select: none;
`;

const DisplayPicture = styled.img`
    width: 42px;
    height: 42px;
    box-sizing: border-box;
    border-radius: 50%; /* Use border-radius to create a circular shape */
    object-fit: cover; /* Crop the image to cover the container */
    object-position: top center; /* Position the image at the top center */
    border: 2px solid transparent; /* Default border color */
    position: relative;
    z-index: 1;
`;

export default function Avatar({
                                user,
                                icon,
                               }) {
  const { theme, colorSet } = useChat();
  const [avatarIcon, setAvatarIcon] = useState(null);

  const avatarMap = {
    general: generalIcon,
    hard: hardIcon,
    wine: wineIcon,
    japanese: japaneseIcon,
    maira: mairaIcon
    // Add more avatar types and their corresponding image paths here
  };

  useEffect(() => {
    if (icon && icon.trim().length > 0 && avatarMap.hasOwnProperty(icon)) {
      setAvatarIcon(avatarMap[icon]);
    }
  }, [icon, avatarMap]);

  const avatarBorderColor = colorSet["--incoming-message-avatar-border-color"];

  return (
      <DPContainer>
        <DisplayPicture
            onError={() => {
              setAvatarIcon(mairaIcon);
            }}
            src={avatarIcon}
            style={{borderColor: avatarBorderColor || theme}}
        />
      </DPContainer>
  );
}
